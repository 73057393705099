import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { Select, Option, FormControl, FormLabel } from '@mui/joy';
import Pagination from '@mui/material/Pagination'; // Updated import for Pagination
import { HourglassEmpty, DoDisturb } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function Transactions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [transactionList, setTransactionList] = useState([]);
    const [months, setMonths] = useState([]);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(10);

    // Filter states
    const [filterWallet, setFilterWallet] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterMonthYear, setFilterMonthYear] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser])
        .then(([userResponse]) => {
            // User
            setUser(userResponse.data);
            
        }).catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setIsLoading(false);
        });

        const startMonth = moment();
        const endMonth = moment('2024-08');
        const generatedMonths = [];
        
        let current = startMonth.clone();
        while (current.isSameOrAfter(endMonth, 'month')) {
            generatedMonths.push(current.format('YYYY-MM'));
            current.subtract(1, 'month');
        }
        
        setMonths(generatedMonths);
        fetchTransactions(currentPage);
    }, [currentPage, filterWallet, filterType, filterStatus, filterMonthYear]);

    const fetchTransactions = async (page) => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/transaction/list', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    page,
                    limit: pageSize,
                    transaction_type: filterType,
                    wallet_type: filterWallet,
                    status: filterStatus,
                    month: filterMonthYear ? moment(filterMonthYear).format('MMM YYYY') : ''
                }
            });

            setTransactionList(response.data.data);
            setTotalPages(response.data.pagination.totalPages);
            setTimeout(() => setIsLoading(false), 500);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            navigate("/");
            setIsLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="transactions-page">
            <div className="transactions-header">
                <h2>{t('transactions.title')}</h2>
            </div>

            {/* Filter Section */}
            <div className="filter-section">

                {user.plan_id !== 2 && (
                    <FormControl sx={{ minWidth: 150, marginBottom: 2 }}>
                        <FormLabel>{t('transactions.wallet')}</FormLabel>
                        <Select
                            placeholder={t('transactions.allWallets')}
                            value={filterWallet}
                            onChange={(e, newValue) => setFilterWallet(newValue || '')}
                        >
                            <Option value="">{t('transactions.all')}</Option>
                            <Option value="Cash Wallet">{t('transactions.cashWallet')}</Option>
                            <Option value="Interest Wallet">{t('transactions.interestWallet')}</Option>
                            <Option value="Invest Wallet">{t('transactions.investWallet')}</Option>
                            <Option value="Register Wallet">{t('transactions.registerWallet')}</Option>
                            <Option value="Direct Referral Wallet">{t('wallet.direct_referral')}</Option>
                        </Select>
                    </FormControl>
                )}

                <FormControl sx={{ minWidth: 150, marginBottom: 2 }}>
                    <FormLabel>{t('transactions.type')}</FormLabel>
                    <Select
                        placeholder={t('transactions.allTypes')}
                        value={filterType}
                        onChange={(e, newValue) => setFilterType(newValue || '')}
                    >
                        <Option value="">{t('transactions.all')}</Option>
                        <Option value="Deposit">{t('transactions.deposit')}</Option>
                        <Option value="Withdrawal">{t('transactions.withdrawal')}</Option>
                        <Option value="Transfer">{t('transactions.transfer')}</Option>
                        <Option value="Receive">{t('transactions.receive')}</Option>
                        <Option value="Interest">{t('transactions.interest')}</Option>
                        {user.plan_id !== 2 && (
                            <Option value="Pledge">{t('transactions.pledge')}</Option>
                        )}
                        <Option value="Invest">{t('transactions.invest')}</Option>
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 150, marginBottom: 2 }}>
                    <FormLabel>{t('transactions.status')}</FormLabel>
                    <Select
                        placeholder={t('transactions.allStatuses')}
                        value={filterStatus}
                        onChange={(e, newValue) => setFilterStatus(newValue || '')}
                    >
                        <Option value="">{t('transactions.all')}</Option>
                        <Option value="Completed">{t('transactions.completed')}</Option>
                        <Option value="Pending">{t('transactions.pending')}</Option>
                        <Option value="Failed">{t('transactions.failed')}</Option>
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 150, marginBottom: 2 }}>
                    <FormLabel>{t('transactions.month')}</FormLabel>
                    <Select
                        placeholder={t('transactions.selectMonth')}
                        value={filterMonthYear}
                        onChange={(e, newValue) => setFilterMonthYear(newValue || '')}
                    >
                        <Option value="">{t('transactions.all')}</Option>
                        {months.map((month) => (
                            <Option key={month} value={month}>
                                {moment(month).format('MM/YYYY')}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {/* Transaction List */}
            {transactionList.length === 0 ? (
                <p className="no-transactions" style={{ textAlign: 'center' }}>{t('transactions.noTransactions')}</p>
            ) : (
                transactionList.map((transaction, index) => (
                    <div className="transaction-item" key={index}>
                        <div className="transaction-details">
                           <h4 className="transaction-title">
                                {transaction.description.startsWith('P2P Transfer to') ? (
                                    <>
                                        {t('transactionsDesc.P2P Transfer to')} {transaction.description.replace('P2P Transfer to ', '')}
                                    </>
                                ) : transaction.description.startsWith('P2P Receive from') ? (
                                    <>
                                        {t('transactionsDesc.P2P Receive from')} {transaction.description.replace('P2P Receive from ', '')}
                                    </>
                                ) : (
                                    t(`transactionsDesc.${transaction.description}`)
                                )}

                                {transaction.status === 'Pending' && (
                                    <HourglassEmpty sx={{ fontSize: 18, color: '#f0ad4e', marginLeft: '3px' }} />
                                )}
                                {transaction.status === 'Rejected' && (
                                    <DoDisturb sx={{ fontSize: 18, color: 'red', marginLeft: '3px' }} />
                                )}
                            </h4>
                            <p className="transaction-category">
                                {moment(transaction.transaction_date).format('DD/MM/YYYY HH:mm:ss')}
                            </p>
                        </div>
                        <div className="transaction-amount">
                            <p className="amount">
                                {transaction.transaction_type === 'Deposit' || transaction.transaction_type === 'Interest' || transaction.transaction_type === 'Receive' || transaction.transaction_type === 'Refund' ? '+' : '-'}
                                {transaction.amount} { t('wallet.currency') }
                            </p>
                            {transaction.wallet_type === "Cash Wallet" && <p className="payment-method">{t('transactions.cashWallet')}</p>}
                            {transaction.wallet_type === "Invest Wallet" && <p className="payment-method">{t('transactions.investWallet')}</p>}
                            {transaction.wallet_type === "Interest Wallet" && <p className="payment-method">{t('transactions.interestWallet')}</p>}
                            {transaction.wallet_type === "Register Wallet" && <p className="payment-method">{t('transactions.registerWallet')}</p>}
                            {transaction.wallet_type === "Direct Referral Wallet" && <p className="payment-method">{t('wallet.direct_referral')}</p>}
                        </div>
                    </div>
                ))
            )}

            {/* Pagination Controls */}
            <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                />
            </div>
        </div>
    );
}

export default Transactions;