import React, { useState, useEffect } from 'react';
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, Button } from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import SendMoneyImage from '../assets/images/infographic-send.png';
import { blue } from '@mui/material/colors';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Loading from '../shared/Loading';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Group = () => {
    const { t } = useTranslation(); // Initialize translation
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [group, setGroup] = useState(null);
    const [downlines, setDownlines] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [user, setUser] = useState(null);
    const [cashWallet, setCashWallet] = useState(null);
    const [interestWallet, setInterestWallet] = useState(null);
    const [investWallet, setInvestWallet] = useState(null);
    const [amount, setAmount] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const downline = queryParams.get('downline');

    // Filter contacts based on search term
    const filteredDownlines = downlines.filter(downline => 
        downline.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        downline.last_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        downline.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        var userGroupUrl = '/api/user/groups';

        if (downline) {
            userGroupUrl += `?downline=${downline}`;
        }

        const fetchUserGroups = axios.get(userGroupUrl, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser, fetchUserWallets, fetchUserGroups])
          .then(([userResponse, walletsResponse, groupResponse]) => {
            setUser(userResponse.data);
            setGroup(groupResponse.data);
            setDownlines(groupResponse.data.invitees);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }).catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setIsLoading(false);
        });
    }, []);

    // Handle selecting a contact to proceed with
    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        setAmount(''); // Reset amount on new contact selection
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box className="send-money-page">
            <img src={SendMoneyImage} alt="Send Money" className="header-image" />
            <Typography variant="h5" className="send-money-title">{downline ? group.first_name + ' ' + group.last_name : ''} {t('group.title')}</Typography>
            <Typography className="send-money-description">
                {t('group.description')}
            </Typography>
            <Typography className="send-money-description">
                <Link
                    to="/group-list" // Replace with your target route
                >
                    {t('group.viewTree')}
                </Link>
            </Typography>

            <>
                {/* Search Field */}
                <TextField
                    label={t('group.contactLabel')}
                    placeholder={t('group.searchPlaceholder')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="search-bar"
                    InputProps={{
                        style: {
                            borderRadius: 12,
                            backgroundColor: '#ffffff'
                        }
                    }}
                />

                {/* Back Button */}
                {downline && (
                    <Box textAlign="center" sx={{ my: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.history.back()}
                            sx={{
                                backgroundColor: '#1976d2',
                                color: '#fff',
                                textTransform: 'none',
                                borderRadius: 12,
                                padding: '8px 32px'
                            }}
                        >
                            {t('group.back')}
                        </Button>
                    </Box>
                )}

                {/* Contact List */}
                <List>
                    {filteredDownlines.length > 0 ? (
                        filteredDownlines.map(contact => (
                            <ListItem key={contact.id} className="contact-item">
                                <ListItemAvatar>
                                    <Avatar 
                                        alt={`${contact.first_name || ''} ${contact.last_name || ''}`.trim()} 
                                        src={require(`../assets/images/avatar/${contact?.profile_picture_id}.png`)} 
                                    />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={`${contact.first_name || ''} ${contact.last_name || ''}`.trim()}
                                    secondary={t('group.totalInvest', {amount: contact.total_deposit})} 
                                    primaryTypographyProps={{ className: 'contact-name' }} 
                                    secondaryTypographyProps={{ className: 'contact-phone' }}
                                />
                                <IconButton edge="end" aria-label="transfer" className="transfer-button" onClick={() => window.location.href = `/group?downline=${contact.uuid}`}>
                                    <ArrowCircleRight style={{ color: blue[800] }} />
                                </IconButton>
                            </ListItem>
                        ))
                    ) : (
                        <Typography align="center" sx={{ color: '#888', mt: 2 }}>
                            {t('group.emptyGroup')}
                        </Typography>
                    )}
                </List>
            </>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default Group;
