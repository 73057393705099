// InvestCash.js
import { Box, Button, Card, FormControl, FormLabel, Input, Radio, RadioGroup, Typography, Select, Option } from '@mui/joy';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';

function InvestCash() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0); // Minimum amount is $100
  const [investWalletAmount, setInvestWalletAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [walletAddress, setWalletAddress] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [user, setUser] = useState(null);
  const [cashWallet, setCashWallet] = useState(null);
  const [interestWallet, setInterestWallet] = useState(null);
  const [investWallet, setInvestWallet] = useState(null);
  const navigate = useNavigate();

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser, fetchUserWallets])
        .then(([userResponse, walletsResponse]) => {
            // User
            setUser(userResponse.data);

            // Wallets
            walletsResponse.data.map(wallet => {
                if (wallet['wallet_type'] === "Cash Wallet") {
                    setCashWallet(wallet);
                } else if (wallet['wallet_type'] === "Interest Wallet") {
                    setInterestWallet(wallet);
                } else if (wallet['wallet_type'] === "Invest Wallet") {
                    setInvestWallet(wallet);
                }
            })

            setTimeout(() => {
                setIsLoading(false);
            }, 500)
            
        }).catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setIsLoading(false);
        });
    }, []);

  const handleWalletAddressChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const handleInvest = async () => {
    if (totalAmount >= 100 && amount <= cashWallet['balance'] && investWalletAmount <= investWallet['balance']) {
      setError(null);
      setIsLoading(true);

      const requestBody = {
        cashWalletAmount: amount,
        investWalletAmount: investWalletAmount
      };
      
      try {
        const response = await axios.post('/api/user/invest', 
          requestBody,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
  
        if (response.status === 200) {
          navigate('/success', { state: { message: t('invest.successMessage') } }); // Translation
        } else {
          setSnackbarMessage('Request failed.');
          setSnackbarOpen(true);
        }

        setIsLoading(false);

      } catch (error) {
        setSnackbarMessage(error.response?.data.message || t('invest.errorMessage')); // Translation
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } else {
      setError(t('invest.invalidInputError')); // Translation
    }
  };

  const handlePaymentMethodChange = (newValue) => {
    setPaymentMethod(newValue);
    if (newValue === 'register') {
      navigate('/invest-register'); // Navigate on change
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="withdraw-page">
      <Card variant="outlined" className="withdraw-card">

        {/* Back Button */}
        <Box display="flex" justifyContent="start" mt={3} ml={2}>
          <Button
            variant="solid"
            onClick={() => navigate(-1)} // Go back to the previous page
            sx={{
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                opacity: 0.9,
              },
            }}
          >
            {t('group.back')}
          </Button>
        </Box>
        
        <Typography level="h4" className="withdraw-title">{t('invest.title')}</Typography> {/* Translation */}
        <Typography level="body1" className="withdraw-description">
          {t('invest.description3')} {/* Translation */}
        </Typography>
        
        {/* Amount Input */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('invest.amountLabel')}</FormLabel> {/* Translation */}
          <Input
            value={amount}
            onChange={(e) => {
                let inputAmount = e.target.value;

                // Enforce two decimal places
                if (inputAmount.includes('.')) {
                    const [integerPart, decimalPart] = inputAmount.split('.');
                    if (decimalPart.length > 2) {
                        inputAmount = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                }

                // Convert to a float and limit the range
                const amountFloat = parseFloat(inputAmount) || 0;
                if (amountFloat >= 0 && amountFloat <= cashWallet['balance']) {
                    setAmount(parseFloat(inputAmount));
                    setTotalAmount(parseFloat(investWalletAmount) + (parseFloat(inputAmount) || 0));
                }

            }}
            type="number"
            placeholder={t('invest.amountLabel')}
            min="100"
            max={cashWallet['balance']}
          />
          {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
        </FormControl>

        <Typography style={{textAlign: 'left', fontSize: '0.8rem', color: 'grey'}}>
          {t('invest.maxCashAmount', {balance: cashWallet['balance']})} {/* Translation with variable */}
        </Typography>

        <Typography level='h3' style={{textAlign: 'right'}}>
            {t('invest.totalAmount', {amount: totalAmount})} {/* Translation with variable */}
        </Typography>

        <Typography style={{textAlign: 'right', fontSize: '0.8rem', color: 'grey'}}>
          {t('invest.investMessage')} {/* Translation with variable */}
        </Typography>

        {/* Confirm Button */}
        <Button 
          variant="solid" 
          color="primary" 
          onClick={handleInvest} 
          className="withdraw-button"
          disabled={totalAmount < 100 || amount > cashWallet['balance'] || investWalletAmount > investWallet['balance']} // Only disable if less than $10 or no address
        >
          {t('invest.confirm')} {/* Translation */}
        </Button>
      </Card>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default InvestCash;