import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { ListItemContent, ListItemDecorator } from '@mui/joy';
import { LinearProgress, Typography } from '@mui/material';
import { KeyboardArrowRight, AttachMoney, ArrowUpward, CompareArrows, TrendingUp } from '@mui/icons-material';
import PaymentsIcon from '@mui/icons-material/Payments';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SavingsIcon from '@mui/icons-material/Savings';
import CasinoIcon from '@mui/icons-material/Casino';
import BalanceIcon from '@mui/icons-material/Balance';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode to decode the token

function Wallet() {
    const { t } = useTranslation(); // Initialize translation

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('Cash');
    const [user, setUser] = useState(null);
    const [cashWallet, setCashWallet] = useState(null);
    const [interestWallet, setInterestWallet] = useState(null);
    const [investWallet, setInvestWallet] = useState(null);
    const [registerWallet, setRegisterWallet] = useState(null);
    const [directReferralWallet, setDirectReferralWallet] = useState(null);
    const [investWalletProgress, setInvestWalletProgress] = useState(null);
    const [transactionList, setTransactionList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [enrolledDailyRoi, setEnrolledDailyRoi] = useState(false);
    const [canInvest, setCanInvest] = useState(true);
    const [isMasterPassword, setIsMasterPassword] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const targetAmount = 5000;
    const minAmount = 100;
    const currentAmount = 500;
    
    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        setIsMasterPassword(decodedToken.masterPassword);

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserTransactions = axios.get('/api/transaction/list', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            params: {
                transaction_type: 'Interest'
            }
        });

        const fetchUserInvest = axios.get('/api/user/invest', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        // Execute all requests concurrently
        Promise.all([fetchUser, fetchUserWallets, fetchUserTransactions, fetchUserInvest])
          .then(([userResponse, walletsResponse, transactionResponse, fetchUserInvestResponse]) => {
            // User
            setUser(userResponse.data);

            // Transactions
            var transactionData = transactionResponse.data.data;
            setTransactionList(transactionData);

            // Get today's date in GMT+8
            const today = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
            // Filter and sum up amounts by wallet_type for today only
            const walletSums = transactionData
                .filter(transaction => moment(transaction.transaction_date).tz('Asia/Singapore').format('YYYY-MM-DD') === today)
                .reduce((acc, transaction) => {
                    const walletType = transaction.wallet_type;
                    const amount = parseFloat(transaction.amount);

                    if (!acc[walletType]) {
                        acc[walletType] = 0;
                    }

                    // Add amount and ensure 2 decimal precision
                    acc[walletType] = parseFloat((acc[walletType] + amount).toFixed(2));
                    return acc;
                }, { 'Cash Wallet': 0.00, 'Interest Wallet': 0.00, 'Invest Wallet': 0.00, 'Register Wallet': 0.00, 'Direct Referral Wallet': 0.00 });
            
            // Wallets
            walletsResponse.data.map(wallet => {
                if (wallet['wallet_type'] === "Cash Wallet") {
                    wallet['profitToday'] = walletSums['Cash Wallet'];
                    setCashWallet(wallet);
                } else if (wallet['wallet_type'] === "Interest Wallet") {
                    wallet['profitToday'] = walletSums['Interest Wallet'];
                    setInterestWallet(wallet);
                } else if (wallet['wallet_type'] === "Invest Wallet") {
                    wallet['profitToday'] = walletSums['Invest Wallet'];
                    setInvestWallet(wallet);
                } else if (wallet['wallet_type'] === "Register Wallet") {
                    wallet['profitToday'] = walletSums['Register Wallet'];
                    setRegisterWallet(wallet);
                } else if (wallet['wallet_type'] === "Direct Referral Wallet") {
                    wallet['profitToday'] = walletSums['Direct Referral Wallet'];
                    setDirectReferralWallet(wallet);
                }
            })

            // Invest
            // setCanInvest(fetchUserInvestResponse.data.invest);

            setTimeout(() => {
                setIsLoading(false);
            }, 500)
            
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setError(error.message);
            setIsLoading(false);
        });
    }, []);

    const handleEnrollDaiyRoi = async () => {
        setIsLoading(true);

        try {
            const response = await axios.post('/api/user/wallets/daily-roi', {},
              {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
            );
      
            if (response.status === 200) {
                setTimeout(() => {
                    navigate('/success', { state: { message: t('modal.enrollSuccess', { interest_rate: response.data.rate.toFixed(2) }) } });
                    setIsLoading(false);
                }, 500)
            } else {
              setSnackbarMessage(t('modal.requestFailed'));
              setSnackbarOpen(true);
              setIsLoading(false);
            }
        
        } catch (error) {
            setSnackbarMessage(error.response?.data.message || t('modal.requestFailed'));
            setSnackbarOpen(true);
            setIsLoading(false);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
          case 'Cash':
            return (
                <>
                    <div className='wallet-section' style={{justifyContent: 'center'}}>
                        <div className="wallet-card wallet-card-default" style={{height: "140px", width: "95%"}}>
                            <div className="card-content">
                                <div className="card-header">
                                    <h4>{ t('walletpage.cashWallet') }</h4>
                                    <AccountBalanceWalletIcon className='validity' fontSize="small" />
                                </div>

                                <p className="profit-text">+{cashWallet['profitToday'].toFixed(2)} { t('wallet.currency') } { t('walletpage.today') }</p>
                                <h2 className='balance-text'>{cashWallet['balance']} { t('wallet.currency') }</h2>
                                
                            </div>
                        </div>

                    </div>

                    <div className='wallet-section' style={{justifyContent: 'center'}}> 
                        <List sx={{
                                "--List-gap": "10px",
                                "--ListItem-paddingY": "10px"
                            }}>
                            <ListItem>
                                <ListItemButton variant="plain" disabled={!canInvest} onClick={() => navigate('/deposit')}>
                                    <ListItemDecorator><AttachMoney /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.deposit') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemButton variant="plain" disabled={isMasterPassword} onClick={() => navigate('/withdraw?wallet=Cash Wallet')}>
                                    <ListItemDecorator><PaymentsIcon /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.withdraw') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemButton variant="plain" onClick={() => navigate('/transfer')}>
                                    <ListItemDecorator><CompareArrows /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.transfer') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemButton 
                                    variant="plain" 
                                    disabled={!canInvest}
                                    onClick={() => navigate('/invest')}
                                >
                                    <ListItemDecorator>
                                        <TrendingUp />
                                    </ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.invest') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton variant="plain" onClick={() => navigate('/convert')}>
                                    <ListItemDecorator><CurrencyExchangeIcon /></ListItemDecorator>
                                    <ListItemContent>{ t('convert.title') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    
                </>
                
            );
          case 'Interest':
            return (
                <>
                    <div className='wallet-section' style={{justifyContent: 'center'}}>
                        <div className="wallet-card wallet-card-variation1" style={{height: "140px", width: "95%"}}>
                            <div className="card-content">
                                <div className="card-header">
                                    <h4>{ t('walletpage.interestWallet') }</h4>
                                    <AllInclusiveIcon className='validity' fontSize="small" />
                                </div>
                                
                                <p className="profit-text">+{interestWallet['profitToday'].toFixed(2)} { t('wallet.currency') } { t('walletpage.today') }</p>
                                <h2 className='balance-text'>{interestWallet['balance']} { t('wallet.currency') }</h2>
                            </div>
                        </div>
                    </div>

                    <div className='wallet-section' style={{justifyContent: 'center'}}> 
                        <List sx={{
                                "--List-gap": "10px",
                                "--ListItem-paddingY": "10px"
                            }}>
                            
                            <ListItem>
                                <ListItemButton variant="plain" onClick={() => navigate('/cashout')}> 
                                    <ListItemDecorator><PaymentsIcon /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.cashout') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemButton variant="plain" onClick={() => navigate('/pledge')}>
                                    <ListItemDecorator><BalanceIcon /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.pledge') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                            
                        </List>
                    </div>
                    
                </>
                
            );
          case 'Invest':
            return (
                <>
                    <div className='wallet-section' style={{justifyContent: 'center'}}>
                        <div className="wallet-card wallet-card-variation2" style={{height: "140px", width: "95%"}}>
                            <div className="card-content">
                                <div className="card-header">
                                    <h4>{ t('walletpage.investWallet') }</h4>
                                    <SavingsIcon className='validity' fontSize="small" />
                                </div>
                                <p className="profit-text">+{investWallet['profitToday'].toFixed(2)} { t('wallet.currency') } { t('walletpage.today') }</p>
                                <h2 className='balance-text'>{investWallet['balance']} { t('wallet.currency') }</h2>
                                
                            </div>
                        </div>

                    </div>

                    <div className='wallet-section' style={{justifyContent: 'center'}}> 
                        <List sx={{
                                "--List-gap": "10px",
                                "--ListItem-paddingY": "10px"
                            }}>
                            <ListItem>
                                <ListItemButton 
                                    variant="plain" 
                                    disabled={!canInvest}
                                    onClick={() => navigate('/invest')}
                                >
                                    <ListItemDecorator>
                                        <TrendingUp />
                                    </ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.invest') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    
                </>
                
            );
          case 'Register':
            return (
                <>
                    <div className='wallet-section' style={{justifyContent: 'center'}}>
                        <div className="wallet-card wallet-card-variation3" style={{height: "140px", width: "95%"}}>
                            <div className="card-content">
                                <div className="card-header">
                                    <h4>{ t('transactions.registerWallet') }</h4>
                                    <SavingsIcon className='validity' fontSize="small" />
                                </div>
                                <p className="profit-text">+{registerWallet['profitToday'].toFixed(2)} { t('wallet.currency') } { t('walletpage.today') }</p>
                                <h2 className='balance-text'>{registerWallet['balance']} { t('wallet.currency') }</h2>
                                
                            </div>
                        </div>

                    </div>

                    <div className='wallet-section' style={{justifyContent: 'center'}}> 
                        <List sx={{
                                "--List-gap": "10px",
                                "--ListItem-paddingY": "10px"
                            }}>
                            <ListItem>
                                <ListItemButton 
                                    variant="plain" 
                                    disabled={!canInvest}
                                    onClick={() => navigate('/invest-register')}
                                >
                                    <ListItemDecorator>
                                        <TrendingUp />
                                    </ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.invest') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton variant="plain" onClick={() => navigate('/transfer-register')}>
                                    <ListItemDecorator><CompareArrows /></ListItemDecorator>
                                    <ListItemContent>{ t('walletpage.transfer') }</ListItemContent>
                                    <KeyboardArrowRight />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    
                </>
                
            );
            case 'Direct Referral':
                return (
                    <>
                        <div className='wallet-section' style={{justifyContent: 'center'}}>
                            <div className="wallet-card wallet-card-variation4" style={{height: "140px", width: "95%"}}>
                                <div className="card-content">
                                    <div className="card-header">
                                        <h4>{ t('wallet.direct_referral') }</h4>
                                        <SavingsIcon className='validity' fontSize="small" />
                                    </div>
                                    <p className="profit-text">+{directReferralWallet['profitToday'].toFixed(2)} { t('wallet.currency') } { t('walletpage.today') }</p>
                                    <h2 className='balance-text'>{directReferralWallet['balance']} { t('wallet.currency') }</h2>
                                    
                                </div>
                            </div>
    
                        </div>
    
                        <div className='wallet-section' style={{justifyContent: 'center'}}> 
                            <List sx={{
                                    "--List-gap": "10px",
                                    "--ListItem-paddingY": "10px"
                                }}>
                                <ListItem>
                                    <ListItemButton variant="plain" disabled={isMasterPassword} onClick={() => navigate('/withdraw?wallet=Direct Referral Wallet')}>
                                        <ListItemDecorator><PaymentsIcon /></ListItemDecorator>
                                        <ListItemContent>{ t('walletpage.withdraw') }</ListItemContent>
                                        <KeyboardArrowRight />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </div>
                        
                    </>
                    
                );
          default:
            return null;
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
    if (isLoading) {
        return <Loading />;
    }
    
    return (
        <div className="wallet-page">
            <h2>{ t('walletpage.title') }</h2>
            <div className="wallet-tabs">
                <button className={activeTab === 'Cash' ? 'active' : ''} onClick={() => setActiveTab('Cash')}>{ t('walletpage.cashWallet') }</button>
                <button className={activeTab === 'Interest' ? 'active' : ''} onClick={() => setActiveTab('Interest')}>{ t('walletpage.interestWallet') }</button>
                <button className={activeTab === 'Invest' ? 'active' : ''} onClick={() => setActiveTab('Invest')}>{ t('walletpage.investWallet') }</button>
                <button className={activeTab === 'Register' ? 'active' : ''} onClick={() => setActiveTab('Register')}>{ t('transactions.registerWallet') }</button>
                <button className={activeTab === 'Direct Referral' ? 'active' : ''} onClick={() => setActiveTab('Direct Referral')}>{ t('wallet.direct_referral') }</button>
            </div>
            <div className="wallet-content-container">
                {renderContent()}
            </div>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </div>
    );
}

export default Wallet;